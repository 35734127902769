import Vue from 'vue'
import Vuex from 'vuex'

import escala from './escala'
import auth from './auth'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: true,
    devtools: true,
    namespaced: true,
    state: {
        clientesUnidades: [],
        selectedClientId: null
    },
    mutations: {
        setClientesUnidades(state, val) {
            state.clientesUnidades = val;
        },
        setClientId(state, val) {
            state.selectedClientId = val;
        }
    },
    actions: {
        getAuth(state) {
            return state.isAuth
        }
    },
    modules: {
        auth: auth,
        escala: escala,
    }
})

export default store