<template>

    <div id="cria-etapa3">
        <b-overlay
          :show="tableLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
            <!-- <b-card-group columns> -->
            <b-list-group>

                


                <b-card class="card-evento" bg-variant="light" text-variant="black" title="">               
                    
                    <div>

                        <b-row>
                            <b-col sm="6">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Solicitante:" label-for="input-solcitante">
                                    <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.solicitante"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Nome do Evento:" label-for="input-solcitante">
                                    <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.nome"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Setor:" label-for="input-solcitante">
                                    <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.setor.text"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col sm="6">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Data:" label-for="input-solcitante">
                                    <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.dataView"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Endereço:" label-for="input-solcitante">
                                    <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.endereco"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <!-- <b-button href="#" variant="info" class="botao">Criar escala</b-button> -->
                    <!-- <router-link to="/criar-escala-2" tag="button" class="botao botao-editar" exact>Editar evento</router-link> -->

                    </div>

                </b-card>



            



                <div class="tabela">
                    <b-table striped hover :items="items" :fields="fields">
                    </b-table>
                </div>


                <b-card class="card-total" bg-variant="light" text-variant="black" title="">               
                    
                    <div class="div-total right-align">

                        <b-media right-align vertical-align="center">
                            <template #aside>
                                <h5><p>Total: {{valorTotal}}</p></h5>
                            </template>

                            <div class="div-botao-editar-escala">
                                <b-button href="#" variant="info" class="botao  botao-editar botao-editar-escala" @click="toCriarEtapa2()">Editar escalas</b-button>
                            </div>

                        </b-media>

                    </div>

                </b-card>

                

                <b-card class="card-obs" bg-variant="light" text-variant="black" title="">               
                    
                    <div>

                        <b-row>
                            <b-col sm="12">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Comentários:" label-for="input-comentarios">
                                    <div>
                                        <b-form-textarea
                                        id="textarea-state"
                                        v-model="textComentarios"
                                        rows="4"
                                        ></b-form-textarea>
                                    </div>           
                                </b-form-group>   
                            </b-col>  
                        </b-row>

                    </div>

                </b-card>



                



                <b-card class="card-etapa" bg-variant="light" text-variant="black">
                    
                    <b-card-group deck class="div-circulo">

                        <div class="circulo circulo-ok">
                            <strong class="strong-ok">1</strong>
                            <div class="barra barra-ok"></div>
                            <div class="etapa-texto etapa-texto-ok">
                                Dados iniciais
                            </div>
                            
                        </div>
                        <div class="circulo circulo-ok">
                            <strong class="strong-ok">2</strong>
                            <div class="barra barra-ok"></div>
                            <div class="etapa-texto etapa-texto-ok">
                                Criação de escala
                            </div>
                        </div>
                        <div class="circulo circulo-ok">
                            <strong class="strong-ok">3</strong>
                            <div class="barra barra-falsa"></div>
                            <div class="etapa-texto etapa-texto-ok">
                                Conclusão
                            </div>
                        </div>                

                    </b-card-group>

                </b-card>

                <div class="div-proxima-etapa">
                    <!-- <router-link to="/criar-escala/message" tag="button" class="botao botao-proxima-etapa" exact>Finalizar pedido</router-link> -->
                    <b-button href="#" variant="" class="botao  botao-proxima-etapa" @click="toFinalizarPedido()">Concluir</b-button>
                </div>
                    
                
    
                
            
            <!-- </b-card-group> -->
            </b-list-group>
        </b-overlay>
    </div>

</template>










<script>
import api from '../../services/api'
import { msg } from '@/global'

export default {
    data(){
        return{
            tableLoading: false,
            dadosHeader: {},
            items: [],
            textComentarios: '',
            valorTotal: '0,00',
            fields: [
                'Função', 
                'Quantidade', 
                'Entrada', 
                'Saída', 
                {key: 'Valor', label: 'Valor', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }}
            ],
        }
    },
    methods: {
        toCriarEtapa2() {
            localStorage.setItem('path', '')
            this.$router.push({ name: 'criaEscala2' })
        },
        toFinalizarPedido() {
            this.tableLoading = true;
            // if (this.textComentarios.length < 5) {
            //     msg('Informe o comentário', 'vermelho', 5000)
            //     return
            // }

            const body = {
                id_evento: this.$store.state.escala.idEvento,
                obs: this.textComentarios
            }

            api.put('webcliente/eventos/obs', body)
            .then(() => {
                this.tableLoading = false;
                // console.log(res)
                localStorage.setItem('eventoHeaderEtapa2', null)
                this.$store.commit('escala/setIdEvento', null)

                msg('PEDIDO ADICIONADO COM SUCESSO!!', 'verde', 5000)

                this.$router.push({ name: 'criaEscalaMsg' })

            })
            .catch(err => {
                this.tableLoading = false;
                msg(err.response.data, 'vermelho', 5000);
            })

        }
    },
    created() {
        const local = localStorage.getItem('eventoHeaderEtapa2')
        this.dadosHeader = JSON.parse(local)
        this.textComentarios = this.dadosHeader.comentarios
        // console.log(this.dadosHeader)



        const idEvento = this.$store.state.escala.idEvento 
        console.log('idEvento',idEvento)
        api.get(`webcliente/escalas/${idEvento}`)
        .then(res => {
            
            this.items = res.data.escalas.map(item => {
                const escala = {
                    isActive: true, 
                    Função: item.funcao, 
                    Quantidade: item.quantidade, 
                    Entrada: item.entrada, 
                    Saída: item.saida, 
                    Valor: item.valor_cliente
                }
                return escala
            })

            this.valorTotal = res.data.somaTotal
            this.valorTotal = parseFloat(this.valorTotal)
            this.valorTotal = this.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            // console.log('this.items',this.items)

        })
        .catch(err => msg(err.response.data, 'vermelho', 5000))


    },

};
</script>






<style scoped src="./CriarEtapa3.css">


</style>