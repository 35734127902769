<template>
    
    <div id="page-financeiro">
        <b-list-group>
            <div class="title-page">
                <h2>Financeiro - em aprovação</h2>
                <div class="total-values">
                    <p><b>Valor Solicitado:</b> {{totalRequestedAmountStr}}</p>
                    <p><b>Valor Final:</b> {{totalFinalValueStr}}</p>
                </div>
            </div>

            <div class="tabela">
                <b-table 
                striped 
                hover 
                :items="items" 
                class="text-center" 
                :fields="fields" 
                :busy="load"
                ref="selectableTable"
                >

                    <template #table-busy >
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong></strong>
                        </div>
                    </template>

                    <template #cell(Detalhes)="data">
                        <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->                      
                        <b-icon class="icones" icon="info-circle" scale="1.5" style="color: #C1A474" @click="toDetalhes(data.item)"></b-icon>                    
                    </template>  
                    <template #cell(Aprovar)="data">  
                        <b-icon class="icones" icon="check-circle" scale="1.5" style="color: #C1A474" @click="toAprovacao(data.item)"></b-icon>                    
                    </template>   

                </b-table>
            </div>

        </b-list-group>
    </div>

</template>

<script>
import api from '../../services/api'
import { msg } from '@/global'

export default {
    name: 'GerenciarEscalas',
    data(){
        return{
            dadosHeader: {},
            escalas: [],
            fields: [
                {key: 'solicitante', label: 'Solicitante', formatter: 'solicitante'},
                {key: 'data_evento', label: 'Data Início', formatter: value => {
                    const valores = value.split('-')
                    return valores[2]+'/'+valores[1]+'/'+valores[0]
                }},
                {key: 'nome_evento', label: 'Nome do Evento', formatter: 'nome_evento'},
                {key: 'setor', label: 'Setor', formatter: 'setor'},
                {key: 'valor_solicitado', label: 'Valor solicitado', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'valor_final', label: 'Valor final', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'status', label: 'Status', formatter: 'status'},
                'Detalhes',
                'Aprovar'
            ],
            items: [],
            load: false,
            totalRequestedAmount: 0,
            totalFinalValue: 0,
            totalRequestedAmountStr: '',
            totalFinalValueStr: '',


        }
    },

    created() {
        // console.log('criou financeiro')
        this.getEscalas()
    },

    methods: {
        msg(titulo, texto, cor) {
           this.toastCount++
           this.$bvToast.toast(texto, {
            title: titulo,
            variant: cor,
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
           })
        },
        
        getEscalas() {

            // console.log('getEscalas')
            this.totalRequestedAmount = 0;
            this.totalRequestedAmountStr = '';
            this.totalFinalValue = 0;
            this.totalFinalValueStr = '';

            const ids = this.getIdClienteUnidade
            // console.log('getEscalas2',`webcliente/financeiro?id_cliente=${ids.idCliente}&id_unidade=${ids.idUnidade}`)

            // api.get(`webcliente/financeiro?id_cliente=${ids.idCliente}&id_unidade=${ids.idUnidade}`)
            this.load = true

            api.get(`webcliente/financeiro/${ids.idCliente}/${ids.idUnidade}`)
            .then(res => {

                // console.log(res.data)
                this.items = res.data;
                this.load = false;
                
                for (let index = 0; index < this.items.length; index++) {
                    this.totalRequestedAmount = this.totalRequestedAmount + parseFloat(this.items[index].valor_solicitado);
                    this.totalFinalValue = this.totalFinalValue + parseFloat(this.items[index].valor_final);
                }
                // console.log('[valor requisitado]',this.totalRequestedAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                // console.log('[valor final]',this.totalFinalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                this.totalRequestedAmountStr = this.totalRequestedAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                this.totalFinalValueStr = this.totalFinalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            })
            .catch(err => {
                this.load = false
                msg(err.response.data, 'vermelho', 5000)
            })

        },

        toDetalhes(evento) {
            const idEvento = evento.id_evento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: evento.solicitante,
                nome: evento.nome_evento,
                dataView: evento.data_evento_view,
                data: evento.data_evento,
                setor:{ text: evento.setor },
                status: evento.status,
                endereco: evento.endereco,
                comentarios: evento.obs,
                valorSolicitadoTotal: evento.valor_solicitado,
                valorFinalTotal: evento.valor_final
            }
            localStorage.setItem('telaOrigem', 'financeiroEmAprovacao')       
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'financeiroDetalhes' })  
            
        },

        toAprovacao(evento) {
            // console.log(escala)
            const idEvento = evento.id_evento
            this.$store.commit('escala/setIdEvento', idEvento)

            // const eventoHeaderEtapa2 = {
            //     solicitante: evento.solicitante,
            //     nome: evento.nome_evento,
            //     dataView: evento.data_evento_view,
            //     data: evento.data_evento,
            //     setor:{ text: evento.setor },
            //     endereco: evento.endereco,
            //     comentarios: evento.obs,
            //     valorSolicitadoTotal: evento.valor_solicitado,
            //     valorFinalTotal: evento.valor_final
            // }
            // localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
             
            if(evento.status == 'em aprovação'){
                this.$router.push({ name: 'financeiroAvaliacao' }) 
            } else {
                this.msg('O evento não pôde ser aprovado', 'Ainda há escalas em contestação.', 'warning')
            }
            
        },

        toProfsEscalados(evento) {
            // console.log('escala',escala)
            const idEvento = evento.idEvento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: evento.solicitante,
                nome: evento.nomeEvento,
                dataView: evento.dataView,
                data: evento.dataEvento,
                setor: evento.setor,
                endereco: evento.endereco,
                comentarios: evento.obs,
                valorSolicitadoTotal: evento.valor_solicitado,
                valorFinalTotal: evento.valor_final
            }
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'agendaEscalados' })   
            
        }
    },

    computed: {
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade  
        },
        
    },


}
</script>

<style scoped lang="scss">

    /* #id {
        margin: 30px;
    } */


    #page-financeiro h2 {
        margin-top: 50px;
        margin-bottom: 15px;
        color: rgb(188, 143, 95);
    }


    .tabela {
        width: 80vw;
    }

    img {
        cursor: pointer;
    }

    span {
        margin-top: 30px;
    }

    .icones {
        cursor: pointer;
    }

    table.b-table[aria-busy='true'] {
        opacity: 0.6;
    }


    @media (max-width: 700px) {

        .tabela {
            width: 300px;
            overflow:auto; 
    
        }
    }

    .title-page {
        display: flex;
        justify-content: space-between;
    }
    .total-values {
        margin-top: 40px;
        margin-right: 15px;
        text-align: right;
        p {
            b {
                color: rgb(188, 143, 95);
            }
            font-weight: 600;
            margin-bottom: 0px;
        }
    }

</style>