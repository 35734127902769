import store from "@/stores/global";
import AppConstants from "../appConstants";
import api from "./api";
// import router from "@/config/router";
import localStorageHelper from "@/helpers/local-storage-helper";

class ReportService {
  validateToken(params, body) {
    return new Promise((resolve, reject) => {
      api.post(AppConstants.API_URL_AUTH_VALIDATE_TOKEN, body, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  logout() {
    localStorageHelper.deleteCurrentUser();
    localStorage.setItem('path', '/neutro');
    
    store.commit('auth/toggleMenu', false);
    store.commit('auth/setUser', null);
    store.commit('setClientesUnidades', null);
    window.location = '/login';
  }
  getConfig(url) {
    return new Promise((resolve, reject) => {
      api.get(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  
}

export default new ReportService();
