<template>
    <div id="message">

        <b-card class="card-etapa" bg-variant="" text-variant="black">
            
            <div class="content">
                <strong>
                    Acompanhe o status da sua solicitação na seção AGENDA do menu.<br>
                    Lá estarão informações adicionais como: escalas, gestores responsáveis e mais.
                </strong>

                <router-link to="/escalas" tag="button" class="botao" exact >OK, ENTENDI</router-link>
            </div>

        </b-card>

    </div>
</template>

<script>
export default {
    name: 'Message'
}
</script>

<style scoped>


    .card-etapa {
        margin-top: 80px;
        height: 300px;
        width: 700px;
        color: white;
        background-color: yellowgreen;

        display: flex;
        position: relative;
    }

    .content{
        bottom: 0;
        left: 0;
        border-radius: 7px;
        color: #2b4247;
        background-color: white;
        height: 78%;
        display: flex;
        flex-direction: column;
        position: absolute;   
    }

    .content strong{ 
        padding: 20px;
        padding-bottom: 20px;
        align-items: center;
        text-align: center;
        /* font-size: 24px; */
    }


    .botao {
        border-radius: 50px; 
        padding: 7px 35px;
        margin-top: 30px;
        background: yellowgreen;
        color: #FFF;;
        outline: none;
        border: yellowgreen;
    }

    .botao:hover{
        background: rgb(122, 170, 25);   
    }
    /* .botao:active{
        background: yellowgreen;
    } */


    @media (max-width: 700px) {
        
        .card-etapa {
            margin-top: 80px;
            height: 350px;
            width: 80vw;
        }

        .botao {
        margin-top: 10px;
    }

    }

</style>