<template>
  <div>
    <b-navbar toggleable="lg" type="dark" style="background-color: #555555">
        
        <router-link to="/new-dashboard" exact><img src="../assets/logo-eventos.png" class="logo-eventos" alt=""></router-link>
        <!-- <b-navbar-brand class="nome-empresa" href="#">EventoSP</b-navbar-brand> -->
        <!-- <router-link to="/dashboard" exact class="nome-empresa">EventosSP</router-link> -->

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>

            <b-nav-item-dropdown :text="`${clienteNome}`" class="active item-drop" right>
                <b-dropdown-item href="#" @click="setCliente(cliente)" v-for="cliente in clientes" :key="cliente.id">{{cliente.nome}}</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown :text="`${unidadeNome}`" class="active item-drop" right>
                <b-dropdown-item href="#" @click="setUnidade(unidade)" v-for="unidade in unidades" :key="unidade.id">{{unidade.nome}}</b-dropdown-item>
            </b-nav-item-dropdown>


            <b-nav-item-dropdown text="Solicitação de Eventos" class="item-drop" right :class="{active: unidadeSel}" :disabled="!unidadeSel">
                <!-- <router-link to="/criar-escala-1" tag="b-dropdown-item" exact>Nova Escala</router-link> -->
                <b-dropdown-item href="#" @click="toCriarEscala()">Novo Evento</b-dropdown-item>
                <b-dropdown-item href="#" @click="toGerenciarEscalas()">Gerenciar Eventos</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item href="#" class="item-drop" :class="{active: unidadeSel}" :disabled="!unidadeSel" @click="toAgenda()">Agenda</b-nav-item>
            <!-- <b-nav-item href="#" class="item-drop" :class="{active: unidadeSel}" :disabled="!unidadeSel" @click="toFinanceiro()">Financeiro</b-nav-item> -->

            <b-nav-item-dropdown text="Financeiro" class="item-drop" right :class="{active: unidadeSel}" :disabled="!unidadeSel" v-if="opcaoVisivel">
                <b-dropdown-item href="#" @click="toFinanceiro()">Em aprovação</b-dropdown-item>
                <b-dropdown-item href="#" @click="toFinanceiroAprovados()">Aprovados</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item href="#" class="item-drop" :class="{active: unidadeSel}" :disabled="!unidadeSel" @click="toFaturamento()" v-if="opcaoVisivel">Faturamento</b-nav-item>
            <!-- <b-nav-item href="#" class="item-drop" :class="{active: unidadeSel}" :disabled="!unidadeSel" v-if="opcaoVisivel">Relatórios</b-nav-item> -->

            <b-nav-item-dropdown text="Relatórios" class="item-drop" right :class="{active: unidadeSel}" :disabled="!unidadeSel">
                <b-dropdown-item href="#" @click="toReportWorkNote()">Relatório de Produtividade</b-dropdown-item>
                <b-dropdown-item href="#" @click="toReportFunction()">Relatório de Função</b-dropdown-item>
                <b-dropdown-item href="#" @click="toReportWeeklySummary()">Relatório de Resumo Financeiro</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown class="active" right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                <em>{{userName}}</em>
                </template>
                <b-dropdown-item @click="alterarSenha">Alterar Senha</b-dropdown-item>
                <b-dropdown-item @click="toMaterialDeApoio">Material de Apoio</b-dropdown-item>
                <b-dropdown-item href="#" @click="signOut">Sair</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import authService from '@/services/authService';
import localStorageHelper from '@/helpers/local-storage-helper';
export default {
    data(){
        return{

            userName: '',
            clienteSel: null,
            unidadeSel: false,
            setoresSel: [],
            clienteNome: 'CLIENTE',
            unidadeNome: 'UNIDADE',
            clientesUnidades: [],
            clientes: [],
            unidades: [],

            opcaoVisivel: false
        }
    },

    created() {
        const user = this.$store.state.auth.user 
        this.opcaoVisivel = user.perfil == 'Administrativo' ? true : false

        this.getClientes()
    },

    mounted() {
        this.getUserNome()  
    },
    computed: {
        selectedClientId() {
            return this.$store.state.selectedClientId;
        }
    },

    methods: {

        async setCliente(clienteSel){
            this.clienteSel = clienteSel
            this.clienteNome = clienteSel.nome

            this.unidadeSel = false
            this.unidadeNome = 'UNIDADE'

            const cliente = this.clientesUnidades.filter(item => {
                return item.id == clienteSel.id
            })

            const unidades = cliente[0].unidades
            this.unidades = unidades.map(item => {
                const unidade = {
                    id: item.id,
                    nome: item.nome_unidade,
                    setores: item.setores,
                    endereco: item.endereco+', '+item.numero
                }
                return unidade
            })

            if (this.unidades.length == 1) {
                this.setUnidade(this.unidades[0])
            }

            const idCliente = clienteSel.id;

            // localStorage.setItem('idCliente', idCliente);
            localStorageHelper.setSelectedClientId(idCliente);
            this.$store.commit('setClientId', idCliente);
            
            // this.$router.push({path: '/dashboard'}).catch(()=>{})
            this.$router.push({ name: 'home' })
            await new Promise(r => setTimeout(r, 100));
            this.$router.push({ name: 'new-dashboard' })
           

        },

        setUnidade(unidade){
            this.unidadeSel = unidade
            this.unidadeNome = unidade.nome

            const setores = this.unidadeSel.setores
            const endereco = this.unidadeSel.endereco     
            localStorage.setItem('setores', JSON.stringify(setores))     
            localStorage.setItem('endereco', endereco) 
            
            const ids = {idCliente: this.clienteSel.id, idUnidade: this.unidadeSel.id}
            this.$store.commit('escala/setIdClienteUnidade', ids)
        },

        signOut() {
            authService.logout();
            // console.log('swdwde')
            // localStorage.removeItem('user')
            // localStorage.setItem('path', '/neutro')

            // this.$store.commit('auth/toggleMenu', false)
            // this.$store.commit('auth/setUser', null)
            // this.$store.commit('setClientesUnidades', null)

            // this.$router.push({path: '/login'})
        },

        toCriarEscala() {

            this.$router.push({ name: 'criaEscala1' })
        },

        getUserNome() {
            const authLocal = localStorage.getItem('user')
            const auth = JSON.parse(authLocal)
            this.userName = auth.nome
        },

        getClientes() {
            
            const timer = setInterval(() => {
                console.log('getClientes')
                this.clientes = []
                // const clientes = localStorage.getItem('clientesUnidades')
                this.clientesUnidades = localStorageHelper.getClientesUnidades();
                
                const listaClientes = this.clientesUnidades.map(item => {
                    const cliente = {
                        id: item.id,
                        nome: item.nome_comercial
                    }
                    return cliente
                })

                this.clientes = listaClientes

                if (this.selectedClientId) {
                    const currentClient = listaClientes.find(item => item.id == this.selectedClientId);
                    if (currentClient) {
                        this.setCliente(currentClient);
                    }
                } else if (this.clientes.length == 1) {
                    this.setCliente(listaClientes[0]);
                }

                clearInterval(timer)

            }, 2000)

        },

        toGerenciarEscalas() {
            this.$router.push({ name: 'escalas' })   
        },

        toAgenda() {
            this.$router.push({ name: 'agenda' })   
        },

        toFinanceiro() {
            this.$router.push({ name: 'financeiro' })   
        },

        toFinanceiroAprovados() {
            this.$router.push({ path: '/financeiro/aprovados' })   
        },

        toFaturamento() {
            this.$router.push({ name: 'faturamento' })   
        },
        toReportWorkNote() {
            this.$router.push({ name: 'report-work-note' });
        },
        toReportFunction() {
            this.$router.push({ name: 'report-function' });
        },
        toReportWeeklySummary() {
            this.$router.push({ name: 'report-weekly-summary' });
        },

        alterarSenha() {
            this.$router.push({ name: 'alterarSenha' })   
        },

        toMaterialDeApoio() {
            window.open('http://www.treinamento.eventossp.com.br/faq/web_cliente')
        }

    },
    

};
</script>

<style scoped>

    img {
        height: 40px;
        margin-right: 7px;
    }

    /* .nome-empresa {
        font-weight: bold;
        margin-right: 50px;
        cursor: pointer;
    } */

    .nome-empresa {
        font-weight: bold;
        font-size: 20px;
        color: white;
        /* padding-top: 10px; */
        /* margin-top: 10px; */
        margin-right: 50px;
        cursor: pointer;
        text-decoration: none;
    }

    .item-drop {
        margin-right: 20px;
        /* font-size: 23; */
    }

    .nav-link-sel {
        font-weight: bold;
    }

    .logo-eventos {
        padding: 10px;
        height: auto; 
        width: auto;
        max-width: 160px; 
        max-height: 160px; 
    }



</style>