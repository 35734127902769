import { msg } from '@/global'
import axios from 'axios' 
import authService from './authService'

const api = axios.create({
    baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:4031/' : `${process.env.VUE_APP_URL_API}`
    // baseURL: 'https://testapi.eventossp.app.br/'
})
 
// api.defaults.headers.common['Authorization'] = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Vzc28iOiJ3ZWJDbGllbnRlIiwiYWRtaW4iOmZhbHNlLCJpZFVzZXIiOjEsImNvb3BlcmF0aXZhIjozLCJtYXRyaWN1bGEiOjk2MDAsIm5vbWUiOiJNYXJjb3MgUm9iZXJ0byIsImVtYWlsIjoia2VwYXM3QGdtYWlsLmNvbSIsImlhdCI6MTYwNjkyMjM0NywiZXhwIjoxNjA5NTE0MzQ3fQ.8gG0b38xBcfVwhh0aMuDBEBwZNg1EZ7utCFcpKZyQMY'

const success = res => res
const error = err => {
    if (err.response.status == 404) {
        msg('Requisição não encontrado', 'vermelho', 5000);
    }
    if (err.response.status == 401) {
        msg('Token inválido, favor faça novamente o login', 'vermelho', 5000);
        setTimeout(() => {
            authService.logout();
        }, 1000);
    }
    return Promise.reject(err);
}

api.interceptors.response.use(success, error)

export default api 