import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../pages/Dashboard'
import Home from '../pages/Home'
import CriaEscalaEtapa1 from '../pages/escala/CriarEtapa1'
import CriaEscalaEtapa2 from '../pages/escala/CriarEtapa2'
import CriaEscalaEtapa3 from '../pages/escala/CriarEtapa3'
import MessageEscala from '../pages/escala/Message'
import GerenciarEscalas from '../pages/escala/GerenciarEscalas'
import Agenda from '../pages/agenda/Agenda'
import AgendaDetalhes from '../pages/agenda/Detalhes'
import AgendaEscalados from '../pages/agenda/Escalados'
import Financeiro from '../pages/financeiro/Financeiro'
import FinanceiroDetalhes from '../pages/financeiro/Detalhes'
import FinanceiroEscalados from '../pages/financeiro/ListaProfs'
import FinanceiroMensagem from '../pages/financeiro/Mensagem'
import FinanceiroAvaliacao from '../pages/financeiro/Avaliacao'
import FinanceiroAprovados from '../pages/financeiro/FinanceiroAprovados'
import Faturamento from '../pages/faturamento/Faturamento'
import Login from '../pages/auth/Login'
import NovaSenha from '../pages/auth/NovaSenha'
import AlterarSenha from '../pages/AlterarSenha'
import ReportWorkNote from '../pages/report/ReportWorkNote';
import ReportValue from '../pages/report/ReportValue';
import ReportWeeklySummary from '../pages/report/ReportWeeklySummary';
import NewDashboard from '../pages/NewDashboard';
// import store from '@/stores/global'

Vue.use(VueRouter)


const routes = [
    { name: 'new-dashboard', path: '/new-dashboard', component: NewDashboard, meta: { requiresAuth: true } },
    { name: 'dashboard', path: '/dashboard', component: Dashboard, alias: '/', meta: { requiresAuth: true } },
    { name: 'home', path: '/home', component: Home, meta: { requiresAuth: true } },
    { name: 'criaEscala1', path: '/criar-escala-1', component: CriaEscalaEtapa1, meta: { requiresAuth: true } },
    { name: 'criaEscala2', path: '/criar-escala-2', component: CriaEscalaEtapa2, meta: { requiresAuth: true } },
    { name: 'criaEscala3', path: '/criar-escala-3', component: CriaEscalaEtapa3, meta: { requiresAuth: true } },
    { name: 'criaEscalaMsg', path: '/criar-escala/message', component: MessageEscala, meta: { requiresAuth: true } },
    { name: 'escalas', path: '/escalas', component: GerenciarEscalas, meta: { requiresAuth: true } },
    { name: 'agenda', path: '/agenda', component: Agenda, meta: { requiresAuth: true } },
    { name: 'agendaDetalhes', path: '/agenda/detalhes', component: AgendaDetalhes, meta: { requiresAuth: true } },
    { name: 'agendaEscalados', path: '/agenda/escalados', component: AgendaEscalados, meta: { requiresAuth: true } },
    { name: 'financeiro', path: '/financeiro', component: Financeiro, meta: { requiresAuth: true } },
    { name: 'financeiroDetalhes', path: '/financeiro/detalhes', component: FinanceiroDetalhes, meta: { requiresAuth: true } },
    { name: 'financeiroEscalados', path: '/financeiro/detalhes/escalados', component: FinanceiroEscalados, props: true, meta: { requiresAuth: true } },
    { name: 'financeiroMensagem', path: '/financeiro/mensagem', component: FinanceiroMensagem, props: true, meta: { requiresAuth: true } },
    { name: 'financeiroAvaliacao', path: '/financeiro/avaliacao', component: FinanceiroAvaliacao, props: true, meta: { requiresAuth: true } },
    { name: 'financeiroAprovados', path: '/financeiro/aprovados', component: FinanceiroAprovados, meta: { requiresAuth: true } },
    { name: 'faturamento', path: '/faturamento', component: Faturamento, meta: { requiresAuth: true } },
    { name: 'auth', path: '/login', component: Login },
    { name: 'novaSenha', path: '/nova-senha', component: NovaSenha },
    { name: 'alterarSenha', path: '/alterar-senha', component: AlterarSenha },
    { name: 'report-work-note', path: '/report-work-note', component: ReportWorkNote },
    { name: 'report-function', path: '/report-function', component: ReportValue },
    { name: 'report-weekly-summary', path: '/report-weekly-summary', component: ReportWeeklySummary },
]





const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    // console.log('[beforeEach][to]', to);
    // console.log('[beforeEach][from]', from);
    // if (!store.state.auth.isAuthenticated) {
    //     if (to.name != 'auth') {
    //         router.push({ name: "home" });
    //     }
    // }

    // ...
    // explicitly return false to cancel the navigation
    // return next();
    if (to.path == from.path) {
        return false;
    }
    next();
})


// router.beforeEach((to, from, next) => {

// const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
// const auth = store.state.auth.isMenuVisible 

// console.log(requiresAuth, auth)

// if(requiresAuth && !auth) {
//     next('/login')
// } else {
//     next()
// }





// const pathAnterior = localStorage.getItem('path')

// // console.log("pathAnterior:",pathAnterior,"  estou aqui:",from.path,'   indo para:',to.path)

// if (pathAnterior == '/neutro') {
//     localStorage.setItem('path', '/neutro')
//     next()  
// } else
// if (pathAnterior == to.path) {
//     // console.log('negado')
//     // console.log('---------------------------------')
//     next(false)
// } else {
//     // console.log('Permitido')
//     // console.log('---------------------------------')
//     localStorage.setItem('path', from.path)
//     next()
// }
// })





// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
//     console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

//     console.log('dddddddddd', mapState(['isAuth']))

//     const isAuth =  

//     console.log('vbbbbbbbbbbbbbbbbbbbbbb', isAuth)

//     if (requiresAuth && !isAuth) {
//         next({ name:'auth' })   
//         // next()
//     }else{
//         next()
//     }
// })

export default router