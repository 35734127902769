<template>
    <div id="message">

        <b-card class="card-etapa" bg-variant="" text-variant="black">

            <h2>Avaliar evento</h2>

            
            <div class="content">

                <div id="div-rate">
                    <label for="rating-inline"  variant="warning" class="mb-2"></label>
                    <b-form-rating id="rating-inline" inline value="5" size="lg" no-border variant="warning" class="mb-2" v-model="value"></b-form-rating>
                </div>

                <b-col sm="12" class="msg">
                    <b-form-group label-cols="12" label-cols-lg="12" label="Comentários">
                        <div>
                            <b-form-textarea class="coments"
                            v-model="textComentarios"
                            rows="5"
                            ></b-form-textarea>
                        </div>           
                    </b-form-group>   
                </b-col> 


                <b-row>
                    <b-col sm="12" class="col-botao">
                        <b-button class="botao" variant="success" @click="enviarAvaliacao()">CONFIRMAR</b-button> 
                    </b-col>
                </b-row>
            </div>

        </b-card>

    </div>
</template>

<script>
import api from '../../services/api'
import { msg } from '@/global'

export default {
    name: 'Avaliacao',
    props: ['ids'],
    data() {
        return{
            value: 5,
            textComentarios: ''
        }
    },
    methods: {
        enviarAvaliacao() {

            const dados = {
                id_evento: this.$store.state.escala.idEvento,
                rate: this.value,
                comentarios: this.textComentarios
            }

            api.post(`webcliente/financeiro/avaliar`, dados)
            .then(() => {

                msg('Avaliação enviada com sucesso.', 'verde', 3000)
                this.$router.push({ name: 'financeiro' })   

            })
            .catch(err => msg(err.response.data, 'vermelho', 3000))

        }
    }
}
</script>

<style scoped>


    .card-etapa {
        margin-top: 80px;
        height: 450px;
        width: 700px;
        color: white;
        background-color: yellowgreen;

        display: flex;
        position: relative;
        
    }

    h2 {
        display: flex;
        justify-content: center;
    }

    #div-rate {
        margin-top: 100px;
        height: 50px;
    }

    #rating-inline {
        height: 60px;
        width: 300px;
    }

    .content{
        bottom: 0;
        left: 0;
        border-radius: 7px;
        color: #2b4247;
        background-color: white;
        height: 78%;
        display: flex;
        flex-direction: column;
        position: absolute; 
        
    }

    .msg {
        margin-top: 20px;
    }

    .botao {
        border-radius: 50px; 
        padding: 7px 35px;
        margin-top: 0px;
        /* background: yellowgreen; */
        color: #FFF;;
        outline: none;
        border: yellowgreen;
    }

    .botao:hover{
        background: rgb(122, 170, 25);   
    }
    /* .botao:active{
        background: yellowgreen;
    } */

    .col-botao {
        display: flex;
        justify-content: center;
        margin-top: -150px;
    }

    .botao {
        width: 300px;
        height: 50px;
    }


    @media (max-width: 700px) {
        
        .card-etapa {
            margin-top: 80px;
            height: 350px;
            width: 80vw;
        }

        .botao {
        margin-top: 10px;
    }

    }

</style>