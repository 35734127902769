import Vue from 'vue';
import Router from 'vue-router';

// import Home from './pages/Home';
import AdesaoCad from './pages/adesao/AdesaoCad';
import Documentacao from './pages/adesao/Documentacao';
import Msg from './pages/Msg';
import Erro from './pages/Erro';
import AdesaoErro from './pages/adesao/AdesaoErro';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [

        {
            path: '/adesao/erro',
            component: AdesaoErro
        },

        // {
        //     path: '/adesao/:emp',
        //     component: AdesaoCad,
        //     props: true, name: 'emp'
        // },

        {
            path: '/adesao/pre-cadastro',
            component: AdesaoCad,
            name: 'emp'
        },

        {
            path: '/adesao/documentacao',
            component: Documentacao,

        },

        {
            path: '/msg',
            component: Msg
        },



        {
            path: '*',
            component: Erro
        }
    ]
});


export default router;