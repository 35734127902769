import { render, staticRenderFns } from "./CriarEtapa3.vue?vue&type=template&id=3a2afac7&scoped=true"
import script from "./CriarEtapa3.vue?vue&type=script&lang=js"
export * from "./CriarEtapa3.vue?vue&type=script&lang=js"
import style0 from "./CriarEtapa3.css?vue&type=style&index=0&id=3a2afac7&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2afac7",
  null
  
)

export default component.exports