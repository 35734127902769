import AppConstants from "../appConstants";
import api from "./api";

class EventService {
  getEvents(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_EVENT, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  getClients(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_EVENT_LIST_CLIENTS, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  getSectors(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_EVENT_LIST_SECTORS, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  
}

export default new EventService();
