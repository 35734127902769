export default class AppConstants {
  // PARAMS KEY
  static PARAMS_TOKEN = 'access_token';

  // ********* TIMEOUT VALUES *******************
  static API_REST_TIMEOUT = 15000;

  // ********* DEFAULT PAGINATOR DATA *******************
  static PAGINATOR_DEFAULT_PAGE_SIZE = 10;
  static PAGINATOR_DEFAULT_LIMIT = 20;
  static PAGINATOR_DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50];

  // ********* LOCAL STORAGE KEYS *******************
  static LOCAL_STORAGE_HAS_LOGGED_IN = 'hasLoggedIn';
  static LOCAL_STORAGE_CURRENT_USER = 'user';
  static LOCAL_STORAGE_DRAFT_NEWS = 'draftNews';
  static LOCAL_STORAGE_VISITOR = 'visitor';

  // ********* NAVIGATION URL *******************
  static NAVIGATION_PAGE_HOME = '/home';
  static NAVIGATION_PAGE_LOGIN = '/login';
  static NAVIGATION_PAGE_REGISTER = '/pages/register';
  static NAVIGATION_PAGE_VERSION = '/version';
  static NAVIGATION_PAGE_FORGOT_PASSWORD = '/forgot-password';
  static NAVIGATION_PAGE_RESET_PASSWORD = '/reset-password';
  static NAVIGATION_PAGE_ERROR_404 = '/pages/error-404';
  static NAVIGATION_PAGE_ERROR_500 = '/pages/error-500';
  static NAVIGATION_PAGE_CALLBACK = '/pages/callback';
  static NAVIGATION_PAGE_COMINGSOON = '/pages/comingsoon';
  static NAVIGATION_PAGE_BROWSER_NOT_SUPPORT = '/pages/browser-not-support';
  static NAVIGATION_PAGE_MAINTENANCE = '/pages/maintenance';

  // ********* API URI *******************
  static API_URL_AUTH_VALIDATE_TOKEN = 'validator_token';


  static API_URL_EVENT = 'webcliente/eventos';
  static API_URL_EVENT_LIST_CLIENTS = 'webcliente/eventos/clients';
  static API_URL_EVENT_LIST_SECTORS = 'webcliente/eventos/sectors';

  static API_URL_REPORT_WORK_NOTE = 'webcliente/report/work-note';
  static API_URL_REPORT_FUNCTION = 'webcliente/report/function';
  static API_URL_REPORT_WEEKLY_SUMMARY = 'webcliente/report/weekly-summary';
  static API_URL_REPORT_DASHBOARD = 'webcliente/report/dashboard';


  // ********* BUS EVENT NAME *******************
  static BUS_EVENT_CLEAR_FILTER = 'clearFilter';

  static EVENT_STATUS = {
    APPROVED: 1,
    DIFF_APPROVED: 2
  }

}
