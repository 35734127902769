<template>
  <div id="login">
    <!-- <aside></aside> -->

    <div id="body">
      <div class="loginArea">
        <form @submit.prevent="alterar">
          <input id="senha-atual" type="password" placeholder="Digite a senha atual" v-model="senhas.senhaAtual" />
          <input id="senha-nova" type="password" placeholder="Nova senha" v-model="senhas.senhaNova" />
          <input id="senha-re-nova" type="password" placeholder="Confirme a senha" v-model="senhas.senhaNovaConfirmar" />
          <button type="submit">Alterar senha</button>
        </form>
      </div>

    </div>
  </div>
</template>




<script>
import api from "../services/api";
import { msg } from '@/global'

export default {
  name: "AlterarSenha",
  data() {
    return {

      senhas: {
        senhaAtual: null,
        senhaNova: null,
        senhaNovaConfirmar: null
      }

    };
  },
  methods: {

    alterar() {

      api
        .put("webcliente/signalter", this.senhas)
        .then(() => {
          // console.log(res.data);
          msg('SENHA ALTERADA!', 'verde', 5000)
          this.$router.replace({ name: "dashboard" });
        })
        .catch(err => {

          const erro = err.response.data

          if (erro.msgs) {

            erro.msgs.forEach(item => {
              msg(item, 'vermelho', 5000)
            })

          } 
          else {
            msg(err.response.data, 'vermelho', 3000)
          }
          
        })

    },
  },
};
</script>




<style scoped>

#login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

aside {
  position: absolute;
  left: 0;
  width: 300px;
  height: 100vh;
  background-color: #17a2b8;
  
}

span {
  text-align: center;
  color: rgb(59, 52, 52);
  margin-bottom: 14px;
  font-size: 16px;
}


#body {
    display: flex;
    position: absolute;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100%;
}

#login .loginArea {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 100px;
  max-width: 600px;
  width: 420px;
  height: 330px;
  /* background: #dee4f1; */
  border-radius: 5px;
  position: relative;
  display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

form {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
}

input {
  margin-bottom: 15px;
  height: 40px;
  width: 300px;
  font-size: 18px;
  padding: 10px;
  outline: none;
  border: 0;
  border-radius: 9px;
  background: rgb(241, 241, 241);
}

button {
  margin-top: 10px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  background: #E7B864;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.loginArea a {
  margin-top: 10px;
  text-decoration: none;
  color: #17a2b8;
  font-size: 15px;
  cursor: pointer;
}

img {
  height: 50px;
}
</style>
