<template>

    <div id="cria-etapa1">
        <!-- <b-card-group columns> -->
        <b-list-group>
            <b-card class="card" style="height: 100%" bg-variant="light" text-variant="black" title="Criar Escala">
                <b-card-text>
                <br>
                </b-card-text>
                
                
                <div>

                    <b-form-group label-cols="12" label-cols-lg="2" label="Solicitante" label-for="input-solcitante">
                        <b-form-input id="input-solcitante" class="text-uppercase input-solicitante" readonly :value="solicitante"></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols="12" label-cols-lg="2" label="Nome do Evento" label-for="input-nome-evento" autocomplete="off">
                        <b-form-input id="input-nome-evento" class="" maxlength="50" v-model="nomeEvento" autocomplete="off"></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols="12" label-cols-lg="2" label="Data do Evento" label-for="input-data" style="padding: 0px; margin: 0px">
                        <!-- <b-form-input id="input-default"></b-form-input> -->

                        <!-- <label for="example-input">Choose a date</label> -->
                        <!-- <b-input-group class="mb-3"> -->
                        <b-input-group class="mb-1 choose-date-input">
                            <b-form-input
                                id="example-input"
                                v-model="formatted"
                                type="text"
                                placeholder="DD-MM-YYYY"
                                autocomplete="off"
                                readonly
                                style="background: white"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-form-datepicker
                                v-model="value"
                                button-only
                                right
                                locale="pt-BR"
                                aria-controls="example-input"
                                @context="onContext"
                                ></b-form-datepicker>
                            </b-input-group-append>
                        </b-input-group>
                        <!-- <p class="mb-1">Value: '{{ value }}'</p>
                        <p class="mb-1">Selected: '{{ selectedData }}'</p>
                        <p>Formatted: '{{ formatted }}'</p> -->

                    </b-form-group>

                    <b-form-group label-cols="12" label-cols-lg="2" label="Setor" label-for="input-setor">
                        <b-form-select v-model="selectedSetor" :options="setores" @change="getNomeSetor"></b-form-select>
                        <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
                    </b-form-group>

                </div>


                <b-button variant="info" class="botao" style="margin-top:0px" @click="criarEvento()">Avançar</b-button>
                <!-- <b-button href="#" variant="info" class="botao" @click="criarEvento()">Avançar</b-button> -->
                <!-- <router-link to="/criar-escala-2" tag="button" class="botao" exact>Nova Escala</router-link> -->
             
            </b-card>


            <b-card class="card-etapa" bg-variant="light" text-variant="black">
                
                <b-card-group deck class="div-circulo">

                    <div class="circulo circulo-ok">
                        <strong class="strong-ok">1</strong>
                        <div class="barra"></div>
                        <div class="etapa-texto etapa-texto-ok">
                            Dados iniciais
                        </div>
                        
                    </div>
                    <div class="circulo">
                        <strong>2</strong>
                        <div class="barra"></div>
                        <div class="etapa-texto">
                            Criação de escala
                        </div>
                    </div>
                    <div class="circulo">
                        <strong>3</strong>
                        <div class="barra barra-falsa"></div>
                        <div class="etapa-texto">
                            Conclusão
                        </div>
                    </div>                

                </b-card-group>

            </b-card>
        
        <!-- </b-card-group> -->
        </b-list-group>
    </div>

</template>

<script>
import moment from 'moment'
import api from '../../services/api'
import { msg } from '@/global'

export default {
    data(){
        return{
            nomeEvento: '',
            value: '2020-11-25',
            formatted: '',
            selectedData: '',
            options: [
                { value: {id: 1, nome: 'MArcos', idade: 40}, text: 'GARÇOM' },
                { value: 2, text: 'MECANICO' },
                { value: 3, text: 'COZINHA' },
            ],
            solicitante: '',
            setores: [],
            selectedSetor: null,
            selectedSetorNome: '',
            setorSel: null

        }
    },
    methods: {


        getNomeSetor(el){
            console.log('[getNomeSetor]', el);
            this.setorSel = this.setores.find(item => item.value === el)
            this.selectedSetorNome = this.setorSel.text
        },

        onContext(ctx) {
            this.formatted = ctx.selectedFormatted
            this.dataSelected = ctx.selectedYMD
        },

        criarEvento() {   
            console.log('[criarEvento]');
            const idClienteUnidade = this.getIdClienteUnidade

            const evento = {
                id_cliente: idClienteUnidade.idCliente,
                id_unidade: idClienteUnidade.idUnidade,
                id_setor: this.selectedSetor,
                nome_evento: this.nomeEvento,
                data_evento: this.value,
                data_evento_view: this.formatted
            }

            // console.log('evento',evento)


            const endereco = localStorage.getItem('endereco')

            const eventoHeaderEtapa2 = {
                solicitante: this.solicitante,
                nome: this.nomeEvento,
                dataView: this.formatted,
                data: this.value,
                setor: this.setorSel,
                endereco: endereco,
                comentarios: ''
            }


            api.post('webcliente/eventos', evento)
            .then(res => {

                
                msg('EVENTO CRIADO COM SUCESSO!!', 'verde', 5000)
                localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))
                
                const idEvento = res.data.id_evento
                console.log('idEvento',idEvento)
                this.$store.commit('escala/setIdEvento', idEvento)
                // this.$router.push('/criar-escala-2')
                this.$router.push({ name: 'criaEscala2' })
            })
            .catch(err => msg(err.response.data, 'vermelho', 5000))
        }

    },
    
    computed: {
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade  
        }
    },

    created() {
        this.value = moment(new Date()).format('YYYY-MM-DD')

        const userLocal = localStorage.getItem('user')
        const user = JSON.parse(userLocal)
        this.solicitante = user.nome
        
        
        const setoresLocal = localStorage.getItem('setores')
        const setoresConv = JSON.parse(setoresLocal)
        const setores = setoresConv.map(item => {
            const setor = {
                value: item.id,
                text: item.nome,
                funcoes: item.funcoes
            }

            return setor
        })
        this.setores = setores


        //Logica vinda da tela Etapa2(para bulrlar o sistema de rotas e colocar em mode de edição)
        // const pathRoter = localStorage.getItem('path')
        // if (pathRoter == '/criar-escala-2') {
        //     this.modeEdit = true
        //     localStorage.setItem('path', '')
        // }

    },

};
</script>

<style scoped src="./CriarEtapa1.css">

    /* @import url("./CriarEtapa1.css"); */
    /* @import "./CriarEtapa1.css"; */

</style>