import AppConstants from "@/appConstants";

class LocalStorageHelper {
    saveData(field, value) {
        window.localStorage.setItem(field, value);
    }
    getData(field) {
        return window.localStorage.getItem(field);
    }
    deleteData(field) {
        return window.localStorage.clear(field);
    }

    setLoggedIn(val) {
        this.saveData(AppConstants.LOCAL_STORAGE_HAS_LOGGED_IN, val);
    }
    hasLoggedIn() {
        return this.getData(AppConstants.LOCAL_STORAGE_HAS_LOGGED_IN);
    }

    setCurrentUser(val) {
        this.saveData(AppConstants.LOCAL_STORAGE_CURRENT_USER, val);
    }
    getCurrentUser() {
        const data = this.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER);
        return data ? JSON.parse(data) : null;
    }
    deleteCurrentUser() {
        this.deleteData(AppConstants.LOCAL_STORAGE_CURRENT_USER);
    }
    setClientesUnidades(val) {
        this.saveData('clientesUnidades', val);
    }
    getClientesUnidades() {
        const data = this.getData('clientesUnidades');
        return data ? JSON.parse(data) : [];
    }
    setSelectedClientId(val) {
        this.saveData('idCliente', val);
    }
    getSelectedClientId() {
        const data = this.getData('idCliente');
        return data ? JSON.parse(data) : null;
    }
    
}

export default new LocalStorageHelper();