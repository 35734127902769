import { render, staticRenderFns } from "./CriarEtapa2.vue?vue&type=template&id=72a655b8&scoped=true"
import script from "./CriarEtapa2.vue?vue&type=script&lang=js"
export * from "./CriarEtapa2.vue?vue&type=script&lang=js"
import style0 from "./CriarEtapa2.css?vue&type=style&index=0&id=72a655b8&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a655b8",
  null
  
)

export default component.exports