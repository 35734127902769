import AppConstants from "../appConstants";
import api from "./api";

class ReportService {
  getWorkNote(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_REPORT_WORK_NOTE, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  getReportFunction(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_REPORT_FUNCTION, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  getWeeklySummary(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_REPORT_WEEKLY_SUMMARY, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  getDashboard(params) {
    return new Promise((resolve, reject) => {
      api.get(AppConstants.API_URL_REPORT_DASHBOARD, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // report(params) {
  //     return new Promise((resolve, reject) => {
  //         apiService.downloadPublicFile(AppConstants.API_URL_REPORT_WORK_NOTE_REPORT, params)
  //             .then(response => resolve(response))
  //             .catch(error => reject(error));
  //     });
  // }

  // =====================================================================

}

export default new ReportService();
