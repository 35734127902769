<template>
    
    <div id="page-faturamento"> 
        <b-list-group>
            <div>
                <h2>Gerenciar Faturamento</h2> 
            </div>

            <h5>Filtrar por período: </h5>

            <date-picker  
                id="input-periodo"
                v-model="range"
                lang="pt-br" 
                range
                style="width:220px;"
                type="date"
                format="DD/MM/YYYY"
                @change="getFaturamentoByClienteId"> 
            </date-picker>
                
            <h5>Faturamentos</h5>

            <div class="tabela">
                <b-table 
                    id="faturamento-table"
                    striped 
                    hover 
                    class="text-center" 
                    ref="selectableTable" 
                    :items="items"  
                    :fields="fields"
                    :busy="load"
                    small
                    outlined>

                    <template #table-busy >
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong></strong>
                        </div>
                    </template> 
 
                    <template #cell(nota_fiscal)="row">
                        {{row.item.nota_fiscal}} 
                        <b-button class="btn-sem-borda" type="button" @click="openNf(row.item)"  variant="outline-warning btn-sm" title="open nf">
                            <font-awesome-icon class="ico-pdf" icon="file-pdf" />
                        </b-button>   
                    </template>

                    <template #cell(link_boleto)="row"> 

                        <template v-if="row.item.link_boleto == null"> 
                                Deposito   
                        </template>

                        <template v-else> 
                            Boleto  
                            <b-button class="btn-sem-borda" type="button" @click="openBoleto(row.item)"  variant="outline-warning btn-sm" title="open boleto">
                                <font-awesome-icon class="ico-pdf" icon="file-pdf" />
                            </b-button>  
                        </template>

                    </template>

                    <template #cell(Detalhes)="row">
                        <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->                      
                        <b-icon class="icones" icon="info-circle" scale="1.5" variant="info" @click="getFaturamentoDetalhes(row.item)"></b-icon>                    
                    </template>     
                        
                </b-table>
            </div>

            <b-modal size="xl" id="modal-faturamentoDetalhes" ref="modal-faturamentoDetalhes" hide-footer title="Faturamento - Detalhes">

                
                <div class="tabela-faturamento-detalhes table-responsive">
                    <b-table
                    id="faturamentoDetalhes-table"
                    striped 
                    hover 
                    class="text-center" 
                    ref="selectableTable" 
                    :items="eventos"  
                    :fields="faturamentoDetalhesFields"
                    :busy="load"
                    small
                    outlined
                    ></b-table>
                </div>


            </b-modal>

        </b-list-group> 
    </div>

</template>

<script> 

import dayjs from 'dayjs';
import br from 'dayjs/locale/pt-br';

import api from '../../services/api'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

import { msg } from '@/global'

export default {
    
    name: 'Faturamento',
    components: { DatePicker },
    data(){
        return{
            items: [], 
            fields: [
                {key: 'nota_fiscal', label: 'Nota Fiscal', formatter:  'notaFiscal'},
                {key: 'link_boleto', label: 'Forma de Pagamento', formatter: 'link_boleto'},
                {key: 'data_emissao', label: 'Emissao', formatter: value => { return  this.formatDate(value)  }
                },
                {key: 'data_vencimento', label: 'Vencimento', formatter: value => { return this.formatDate(value)  }
                },
                {key: 'valor', label: 'Valor', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }}, 
                'Detalhes' 
            ],
            range: [],
            
            eventos: [],
            faturamentoDetalhesFields: [
                {key: 'solicitante', label: 'Solicitante', formatter: 'solicitante'},
                {key: 'data_evento', label: 'Data Início', formatter: value => {
                    const valores = value.split('-')
                    return valores[2]+'/'+valores[1]+'/'+valores[0]
                }},
                {key: 'nome_evento', label: 'Nome do Evento', formatter: 'nome_evento'},
                {key: 'setor', label: 'Setor', formatter: 'setor'},
                {key: 'valor_solicitado', label: 'Valor solicitado', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'valor_final', label: 'Valor final', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                {key: 'data_aprovacao', label: 'Data/Hora de aprovação', formatter: 'data_aprovacao'},
                {key: 'nota_fiscal', label: 'NF Emitida', formatter: 'nota_fiscal'},
            ],

            load: false
            
        }
    },

    created() {
    
        // var data = new Date();  
        // data.setMonth( data.getMonth() -1 );

        this.range[0] = new Date(dayjs().subtract(30, 'day').format('YYYY-MM-DD'))
        this.range[1] = new Date() 

        this.getFaturamentoByClienteId()
    },

    methods: {
        
        getFaturamentoByClienteId(){
             
            this.load = true

            const ids = this.getIdClienteUnidade

            if(this.range[0] == null && this.range[1] == null) this.range = ['0', '0']

            api.get(`webcliente/faturamento/${ids.idCliente}/${ids.idUnidade}/${this.range[0]}/${this.range[1]}`)
            .then(res => { 
                this.items = Object.assign(res.data); 
                console.log(this.items)
                this.load = false;
            })
            .catch(err => {
                this.load = false
                msg(err.response.data, 'vermelho', 5000)
            })
        },

        // toDetalhes(){
        //     this.$router.push({ path: '/financeiro/aprovados' })  
        // },

        getFaturamentoDetalhes(faturamento){
          
            // console.log('hfisughsiuf', faturamento)

            const idFaturamento = faturamento.id
            const idCliente = faturamento.id_cliente

            api.get(`webcliente/faturamento/detalhes/${idCliente}/${idFaturamento}`)
            .then(res => {

                // console.log(res.data)
                this.eventos = res.data
                console.log('eventos bem aqui: ', this.eventos)
            })
            .catch(err => msg(err.response.data, 'vermelho', 5000))

            this.$refs['modal-faturamentoDetalhes'].show()

            
        },

        formatDate(date){
       
        
                console.log("Emissao -> [formatDate]")
                if (date == null || date == undefined) return null
                return dayjs(date).locale(br).format('DD-MMM-YYYY') // '25/01/2019' 
         },
         colNFFormat(row){
             return row.item.nota_fiscal + "<div>" + row.item.link_nf + "</div>"

         },
         
      openNf(link){
        window.open(link.link_nf, "_blank");
      },
         
      openBoleto(link){
        window.open(link.link_boleto, "_blank");
      }
    },

    computed: {        
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade  
        }
      
    },
  
}
</script>

<style scoped>

   
    /* #id {
        margin: 30px;
    } */



    .btn-sem-borda{
        border: none;
    }

    .ico-pdf{
        font-size: 18pt;
        color: rgb(219, 158, 89);
    }

    .btnRight {
        float: right;
        margin-top: -40px;
        margin-right: 15px;
    }


    #page-faturamento h2 {
        margin-top: 50px;
        margin-bottom: 15px;
        color: rgb(188, 143, 95);
    }

    #page-faturamento h5 {
        margin: 20px;
        margin-bottom: 15px;
        color: rgb(70, 81, 82);
    }

    .tabela {
        width: 80vw;
    }

    .tabela-faturamento-detalhes {
        width: 72vw;
    }

    img {
        cursor: pointer;
    }

    span {
        margin-top: 30px;
    }

    .icones {
        cursor: pointer;
    }

    table.b-table[aria-busy='true'] {
        opacity: 0.6;
    }


    @media (max-width: 700px) {

        .tabela {
            width: 300px;
            overflow:auto; 
    
        }
    }


    .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    }

    @media screen and (max-width: 576px) {
    .table-responsive {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        }
    }

</style>