<template>

    <div id="page-detalhes">
        <!-- <b-card-group columns> -->
        <b-list-group>

            <!-- <h2>Profissionais Escalados</h2> -->
            <div>
                <h2>
                    <a class="link-page" @click="toAgenda">Gerenciar agenda</a> 
                    > Profissionais Escalados
                </h2>
                <b-button class="btnRight" variant="btn btn-warning btn-sg" @click="toAgenda()"> Voltar</b-button>
            </div>

            

            <b-card class="card-evento" bg-variant="light" text-variant="black" title="">               
                
                <div>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Solicitante:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.solicitante"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Nome do Evento:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.nome"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Setor:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.setor.text"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Data:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.dataView"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Endereço:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante" size="sm" class="text-uppercase" readonly :value="dadosHeader.endereco"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col sm="12">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Comentários:" label-for="input-comentarios">
                                <div>
                                    <b-form-textarea
                                    size="sm"
                                    disabled
                                    id="textarea-state"
                                    v-model="textComentarios"
                                    rows="2"
                                    ></b-form-textarea>
                                </div>           
                            </b-form-group>   
                        </b-col>  
                    </b-row>

                    
                    <!-- <b-button href="#" variant="info" class="botao">Criar escala</b-button> -->
                <!-- <router-link to="/criar-escala-2" tag="button" class="botao botao-editar" exact>Editar evento</router-link> -->

                </div>

            </b-card>


            <div v-if="this.listaProfsComFoto === true">
                <b-button class="botao" variant="success" @click="gerarPdfComFoto">Imprimir lista</b-button>
            </div>
            <div v-else>
                <b-button class="botao" variant="success" @click="gerarPdf">Imprimir lista</b-button>
            </div>
           



            <div class="tabela">
                <h2>Relatório de Escala</h2>
                <b-table id="tabela" striped hover :items="items" :fields="fields"></b-table>
            </div> 

            <div class="tabelaPDF" v-show="false">
                <h2>Relatório de Escala</h2>
                <b-table id="tabelaPDF" striped hover :items="itemsPDF" :fields="fieldsPDF"></b-table>
            </div> 

            <div class="tabelaPDF" v-show="false">
                <h2>Relatório de Escala</h2>
                <b-table id="tabelaPDFComFoto" striped hover :items="itemsPDF" :fields="fieldsPDFComFoto"></b-table>
            </div> 
            
        
        <!-- </b-card-group> -->
        </b-list-group>
    </div>

</template>










<script>
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import api from '../../services/api'
import { msg } from '@/global'
import { logoEventos } from '../../assets/base64/logo'
import dayjs from 'dayjs'




export default {
    data(){
        return{
            dadosHeader: {},
            // fields: ['Função','Setor','Funcao','Qtd','Entrada','Saída','carga total','Orcamamento'],

            fields: [
                {key: 'nome', label: 'Nome', formatter: 'nome'},
                {key: 'funcao', label: 'Função', formatter: 'funcao'},
                {key: 'entrada', label: 'Início', formatter: 'entrada'},
                {key: 'saida', label: 'Saída', formatter: 'saida'},
            ],

            fieldsPDF: [
                {key: 'matricula', label: 'Matrícula', formatter: 'matricula'},
                {key: 'nome', label: 'Nome', formatter: 'nome'},
                {key: 'rg', label: 'RG', formatter: 'rg'},
                {key: 'funcao', label: 'Função', formatter: 'funcao'},
                {key: 'entrada', label: 'Início', formatter: 'entrada'},
                {key: 'saida', label: 'Saída', formatter: 'saida'},
                {key: 'horaextra', label: 'Hora Extra', formatter: 'horaextra'},
                {key: 'assinatura', label: 'Assinatura', formatter: 'assinatura'},
            ],

            fieldsPDFComFoto: [
                {key: 'matricula', label: 'Matrícula', formatter: 'matricula'},
                {key: 'nome', label: 'Nome', formatter: 'nome'},
                {key: 'rg', label: 'RG', formatter: 'rg'},
                {key: 'funcao', label: 'Função', formatter: 'funcao'},
                {key: 'entrada', label: 'Início', formatter: 'entrada'},
                {key: 'saida', label: 'Saída', formatter: 'saida'},
                {key: 'horaextra', label: 'Hora Extra', formatter: 'horaextra'},
                {key: 'assinatura', label: 'Assinatura', formatter: 'assinatura'},
                {key: 'foto', label: 'Foto', formatter: 'foto'},
            ],
            
            items: [],
            itemsPDF: [],
            textComentarios: '',
            evento: null,
            listaProfsComFoto: false
        }
    },

    created() {
        const local = localStorage.getItem('eventoHeaderEtapa2')
        this.dadosHeader = JSON.parse(local)
        this.textComentarios = this.dadosHeader.comentarios
        

        const idEvento = this.$store.state.escala.idEvento 

        api.get(`webcliente/agenda/detalhes/escalados?id_evento=${idEvento}`)
        .then(res => {

            // console.log('resssssss',res.data.profissionais)
            this.evento = res.data
            this.items = this.evento.profissionais
            this.itemsPDF = this.evento.profissionais

            if (this.evento.listaPresenca == 'Com foto') this.listaProfsComFoto = true
            // console.log('com foto ou não', this.listaProfsComFoto)
            
        })
        .catch(err => msg(err.response.data, 'vermelho', 400))


    },

    methods: {

        toAgenda() {  
            this.$router.push({ name: 'agenda' })   
        },

        gerarPdf(){

            const auth = JSON.parse(localStorage.getItem('user'))
            
            var doc = jsPDF('l', 'mm', [297, 210]);

            var imgData = logoEventos()          

            doc.addImage(imgData, 'PNG', 9, 4, 26, 12);

            doc.setDrawColor(0, 0, 0);
            doc.line(0, 18, 500, 18);

            doc.setFontSize(18); 
            var width = doc.internal.pageSize.getWidth()
            doc.text('Relatório de Escala', width/2, 10, { align: 'center' })
                                    
            doc.setFontSize(9);          
            doc.text(117, 16, 'N° do Evento: ' + this.evento.id);

            doc.setFontSize(9);          
            doc.text(158, 16, 'Data: ' + this.evento.data_evento);

            doc.setFontSize(10);          
            doc.text(9, 24, 'Cliente: ' + this.evento.nome_comercial);

            doc.setFontSize(10);          
            doc.text(9, 31, 'Endereço: ' + this.evento.endereco);

            doc.autoTable({ 
                html: '#tabelaPDF',
                margin: {top: 35, left: 9}, 
                didDrawPage: function (data){
                    data.settings.margin.top = 9;
                    data.settings.margin.left = 9;
                },
                theme: 'grid',
                columnStyles: {
                0: {cellWidth: 20},
                1: {cellWidth: 75},
                2: {cellWidth: 30},
                3: {cellWidth: 40},
                4: {cellWidth: 20},
                5: {cellWidth: 20},
                6: {cellWidth: 30},
                7: {cellWidth: 45},
                
            }
            }); 

            var finalY = doc.lastAutoTable.finalY;
            doc.setFontSize(10);
            doc.text(9, (finalY + 5), 'Total de Cooperados: ' + this.itemsPDF.length);

            doc.setDrawColor(0, 0, 0);
            doc.line(0, 200, 500, 200);

            doc.setFontSize(9);          
            doc.text(9, 205, 'Desenvolvido por Technoplus Comércio e Serviços Ltda.');

            doc.setFontSize(9);          
            doc.text(165, 205, 'Usuário: ' + auth.nome);

            doc.setFontSize(9);
            doc.text(210, 205, 'Data: ' + dayjs().format('DD/MM/YYYY'));

            doc.setFontSize(9);
            doc.text(245, 205, 'Hora: ' + dayjs().format('HH:mm'));

            
            
            var pageCount = doc.internal.getNumberOfPages();
            for(var i = 0; i < pageCount; i++) { 
            doc.setPage(i); 
            let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
            doc.setFontSize(9);
            doc.text('Pág ' + pageCurrent + '/' + pageCount, 280, 205);
            }

            //doc.autoTable({ html: '#tabela' })

            // doc.autoTable({
            //     head: [['Name', 'Email', 'Country']],
            //     body: [
            //             ['David', 'david@example.com', 'Sweden'],
            //             ['Castille', 'castille@example.com', 'Spain'],
            //             // ...
            //         ],
            // })

            doc.save('portaria.pdf')
        },

        gerarPdfComFoto(){

            const auth = JSON.parse(localStorage.getItem('user'))
            
            var doc = jsPDF('l', 'mm', [350, 210]);

            var imgData = logoEventos()          

            doc.addImage(imgData, 'PNG', 9, 4, 26, 12);

            doc.setDrawColor(0, 0, 0);
            doc.line(0, 18, 500, 18);

            doc.setFontSize(18); 
            var width = doc.internal.pageSize.getWidth()
            doc.text('Relatório de Escala', width/2, 10, { align: 'center' })
                                    
            doc.setFontSize(9);          
            doc.text(117, 16, 'N° do Evento: ' + this.evento.id);

            doc.setFontSize(9);          
            doc.text(158, 16, 'Data: ' + this.evento.data_evento);

            doc.setFontSize(10);          
            doc.text(9, 24, 'Cliente: ' + this.evento.nome_comercial);

            doc.setFontSize(10);          
            doc.text(9, 31, 'Endereço: ' + this.evento.endereco);

            doc.autoTable({ 
                html: '#tabelaPDFComFoto',
                margin: {top: 35, left: 9}, 
                didDrawPage: function (data){
                    data.settings.margin.top = 9;
                    data.settings.margin.left = 9;
                },
                theme: 'grid',
                columnStyles: {
                0: {cellWidth: 20},
                1: {cellWidth: 75},
                2: {cellWidth: 30},
                3: {cellWidth: 40},
                4: {cellWidth: 20},
                5: {cellWidth: 20},
                6: {cellWidth: 30},
                7: {cellWidth: 45},
                
            }
            }); 

            var finalY = doc.lastAutoTable.finalY;
            doc.setFontSize(10);
            doc.text(9, (finalY + 5), 'Total de Cooperados: ' + this.itemsPDF.length);

            doc.setDrawColor(0, 0, 0);
            doc.line(0, 200, 500, 200);

            doc.setFontSize(9);          
            doc.text(9, 205, 'Desenvolvido por Technoplus Comércio e Serviços Ltda.');

            doc.setFontSize(9);          
            doc.text(165, 205, 'Usuário: ' + auth.nome);

            doc.setFontSize(9);
            doc.text(210, 205, 'Data: ' + dayjs().format('DD/MM/YYYY'));

            doc.setFontSize(9);
            doc.text(245, 205, 'Hora: ' + dayjs().format('HH:mm'));

            
            
            var pageCount = doc.internal.getNumberOfPages();
            for(var i = 0; i < pageCount; i++) { 
            doc.setPage(i); 
            let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
            doc.setFontSize(9);
            doc.text('Pág ' + pageCurrent + '/' + pageCount, 280, 205);
            }

            //doc.autoTable({ html: '#tabela' })

            // doc.autoTable({
            //     head: [['Name', 'Email', 'Country']],
            //     body: [
            //             ['David', 'david@example.com', 'Sweden'],
            //             ['Castille', 'castille@example.com', 'Spain'],
            //             // ...
            //         ],
            // })

            doc.save('portaria.pdf')
        }
    }

};
</script>






<style scoped>

    h2 {
        margin-top: 30px;
        font-size: 28px;
        color: rgb(188, 143, 95);
    }

    .btnRight {
        float: right;
        margin-top: -40px;
        margin-right: 15px;
        margin-bottom: 5px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .link-page {
        font-size: 28px;
        color: rgb(188, 143, 95);  
        cursor: pointer;
    }

    .botao {
        width: 200px;
        margin-top: 30px;
        margin-bottom: 10px;
    }

</style>