<template>

  <div class="backgra">

    <div class="container">
      <div class="img-tec">
        <img
          src="@/assets/logo/technoPlus.png"
          class="img-salus"
          alt="Salus"
        >

      </div>


      <div class="borda mx-auto col-lg-12">

        <div id="msg">
          <div class="">

            <div class="title-top">
              Confira os nossos Serviços e Produtos
            </div>

            <div class="enviar col-lg-12">
              <b-button
                id="buttonEnviar"
                type="button"
                variant="laranja m-3"
                 href="https://technoplus.com.br" target="_blank">Eu quero conhecer
              </b-button>
              

            </div>

       
          </div>
        </div>
        <div class="title-adesao">
          <span>
            Inteligência e Inovação no Desenvolvimento de Soluções em TI
          </span>
        </div>

      </div>

      <!-- <div id="erro">
    <h1>Opss está página não existe :(</h1>

    
</div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.logo {
  width: 100%;
  min-width: 190px;
  height: auto;
  min-height: 80px;
  /* background: url(images/logo.png) no-repeat center center; */
  text-indent: -9999px;
  display: block;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1170px;
  }
}

.fundo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgra {
  width: 100%;
  /* background-color: #bc0000; */
  background-color: #ebeaea;
  height: 240px;
  position: relative;
  z-index: 1;
}

.img-salus {
  margin-top: 59px;
}


.risco {
  margin-bottom: 1rem;
  border-bottom: 2px solid #a1a1a1;
  width: 100%;
}

.title-top {
  margin-top: -2rem;
  display: bloc;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-family: inherit, sans-serif;
  line-height: 46px;
  color: #f67b17;
}

@media screen and (max-width: 480px) {
  .title-top {
    font-size: 30px;
  }
}

.borda {
  width: 65%;
  margin-top: 1rem;
}

@media screen and (max-width: 480px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

@media screen and (max-width: 960px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

#buttonEnviar {
  width: 249px;
  border-radius: 25px;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
  border: 0;
  padding: 8px;
  font-size: 20px;
  transition: all 0.5s;
  outline: none;
}

.enviar {
  display: flex;
  justify-content: center;
}

.btn-laranja {
  color: #fff;
  background-color: #f67b17;
  border-color: #f77c35;
}

.btn-laranja:hover {
  color: #fff;
  background-color: #e49f37;
  border-color: #e49f37;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #d87d06;
}

.btn-laranja:not(:disabled):not(.disabled).active:focus,
.btn-laranja:not(:disabled):not(.disabled):active:focus,
.show > .btn-laranja.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #d87d06;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #d87d06;
}

span {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  font-weight: bolder;
  font-size: 40px;
  color: white;
  border-radius: 25px;
  text-shadow: 2px 2px 2px rgb(158, 94, 35);

  background: #fc8f00;
  background: linear-gradient(to top, #fa871b, #f5a733);

  /* background-color: #ec9854; */
}

/* .img-tec {
  display: inline;

  background-image: url("../assets/logo/tec.png");
  background-repeat: no-repeat; */
  /* background-position: right; */
/* } */
</style>