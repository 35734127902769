<template>
  <div id="dashboard">
    <div>

      <div class="centralizado">
        <h4>Acompanhe abaixo, em tempo real, o status dos eventos: </h4>
      </div>
      
      <div id="chart"  class="centralizado">
        <apexchart type="pie" width="750" :options="chartOptions" :series="series"></apexchart>
      </div>
    

    </div>
  </div>
</template>



<script>
// import Header from "../components/Header";
import VueApexCharts from 'vue-apexcharts'
import api from '../services/api'
import { msg } from '@/global'

export default {
  components: { apexchart: VueApexCharts},
  
  data() {
    return {

          series: [0, 0],
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            colors: ['#7CBAFB', '#00E361', '#FEB0E1', '#FF4560', '#775DD0', '#008FFB', '#00E396', '#FEB019'],
            // colors: ['#A82CF9', '#F9842D', '#6197F1'],
            legend: {
              position: 'left',
              fontSize: '18px',
              itemMargin: {vertical: 10},
            },
            labels: [
              'Eventos solicitados / Eventos alterados',
              'Eventos em processo',
              'Eventos de escala completa',
              'Eventos em andamento', 
              'Eventos para aprovação',
              'Eventos contestados',
              'Eventos em reavaliação',
              'Eventos aprovados'
            ],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 480
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },

          // status: []
    }
  },

  created() {
    
    // this.series = [40, 50, 10],
    this.getDadosGrafico()
    // console.log(this.status)
  }, 

  methods: {
    getDadosGrafico() {
      const idCliente = localStorage.getItem('idCliente')

      api.get(`webcliente/dashboard/${idCliente}`)
      .then(res => {

        this.series = res.data
        // console.log(res.data)
     
      })
      .catch(err => msg(err.response.data, 'vermelho', 5000))
    }
  },
}
</script>



<style scoped>
#dashboard {
  height: 91vh;
  width: 90vw;
  /* background-color: aqua; */
  padding: 35px;
}

.centralizado {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

</style>